import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosConfig from '../../utils/axiosConfig';

export const loginUser = createAsyncThunk('user/login', async ({ username, password }, { rejectWithValue }) => {
  try {
    const access_token = btoa(username + ':' + password);
    const response = await axiosConfig.get('/check-basic-login', { headers: { Authorization: 'Basic ' + access_token } });
    // eslint-disable-next-line no-unused-vars
    const d = await response.data;
    return { access_token: access_token, user: { name: 'admin' } };
  } catch (error) {
    return rejectWithValue({
      error: error.response.data ? error.response.data.message : error.message,
    });
  }
});

export const logoutUser = createAsyncThunk('user/logout', async (_, { rejectWithValue }) => {
  return true;
});
