import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { Navbar } from '../../components/navbar';

const PrivateRoute = ({ component: Component, ...props }) => {
  const { accessToken } = useSelector((state) => state.auth);

  return (
    <div className="p-4">
      <Navbar />
      {!accessToken ? <Navigate to={{ pathname: '/login', state: { from: props.location } }} replace /> : <Component {...props} />}
    </div>
  );
};

export { PrivateRoute };
