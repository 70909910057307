import { configureStore } from '@reduxjs/toolkit';
import { injectStore } from '../utils/axiosConfig';
import authSliceJWT from './auth/authSlice';

const store = configureStore({
  reducer: {
    auth: authSliceJWT,
  },
});

injectStore(store);

export default store;
