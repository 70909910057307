/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import axiosConfig from '../../utils/axiosConfig';
import { baseURL } from '../../utils/config';

import GridLoader from 'react-spinners/GridLoader';
import MaleIcon from '../../assets/male.png';
import FemaleIcon from '../../assets/female.png';
import FileIcon from '../../assets/file.png';
import AIIcon from '../../assets/ai.png';
import { logoutUser } from '../../store/auth/authActions';

const Home = () => {
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();

  const fileuploader = useRef();
  const [selectedFile, setSelectedFile] = useState(null);

  const pprompt = useRef();

  const [loader, setLoader] = useState(false);

  const [candidates, setCandidates] = useState([]);
  const [queryResult, setQueryResult] = useState(null);

  useEffect(() => {
    fetchCandidates();
  }, []);

  const fetchCandidates = () => {
    axiosConfig.get('/candidates').then((res) => {
      console.log(res.data);
      setCandidates(res.data.candidates);
    });
  };

  const upload = () => {
    if (fileuploader.current) fileuploader.current.click();
  };

  const handleFileChange = (event) => setSelectedFile(event.target.files[0]);

  useEffect(() => {
    if (selectedFile) {
      const uplader = async (file) => {
        const formData = new FormData();
        formData.append('file', file);

        try {
          setLoader(true);
          const response = await axiosConfig.post('/upload', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
          console.log('File uploaded successfully:', response.data);
          fetchCandidates();
        } catch (error) {
          console.error('Error uploading file:', error);
        } finally {
          setTimeout(() => {
            setLoader(false);
          }, 1000);
          setSelectedFile(null);
          fileuploader.current.value = '';
        }
      };
      uplader(selectedFile);
    }
  }, [selectedFile]);

  const clear = () => {
    if (pprompt.current) {
      pprompt.current.value = '';
      setQueryResult(null);
    }
  };

  const Seach = () => {
    if (pprompt.current) {
      setLoader(true);
      axiosConfig
        .post('/query', { prompt: pprompt.current.value })
        .then((res) => {
          const data = res.data;
          console.log(data);
          for (let i = 0; i < data.candidates_challenge_data_dict.candidates.length; i++) {
            data.candidates_challenge_data_dict.candidates[i] = {
              ...data.candidates_challenge_data_dict.candidates[i],
              ...data.candidates_list.find((o) => String(o.id) === String(data.candidates_challenge_data_dict.candidates[i].id)),
            };
          }
          setQueryResult(data);
        })
        .catch((err) => {
          console.log(err);
          setQueryResult(null);
        })
        .finally(() => setLoader(false));
    } else {
      setQueryResult(null);
    }
  };

  const candidateData = useMemo(() => {
    return queryResult?.candidates_list || candidates;
  }, [queryResult, candidates]);

  const CandidateListRender = ({ candidate, index }) => {
    return (
      <tr key={candidate?.id || 'candidate-' + index} className="hover:bg-gray-50">
        <td className="flex gap-3 px-6 py-4 font-normal text-gray-900">
          <div className="w-[120px]">
            <div className="relative h-10 w-10 mb-2">
              <img className="h-full w-full rounded-full object-cover object-center" src={candidate?.gender === 'male' ? MaleIcon : FemaleIcon} alt={candidate?.gender || ''} />
              <span className="absolute right-0 bottom-0 h-2 w-2 rounded-full bg-green-400 ring ring-white"></span>
            </div>
            <div className="text-sm">
              <div className="font-bold text-gray-700">{candidate?.name}</div>
              <div className="text-xs text-gray-400">
                {candidate?.gender || ''} {candidate?.age ? 'age ' + candidate.age + ' years' : ''}
              </div>
            </div>
          </div>
        </td>
        <td className="px-6 py-4">
          <div className="flex flex-col justify-between gap-2">
            <div className="w-full">
              {candidate?.ai_comment && (
                <div>
                  <img src={AIIcon} alt="AI" style={{ width: '30px', height: '30px', display: 'inline', verticalAlign: 'bottom' }} />
                  <strong className="mr-1">AI Comment</strong> {candidate?.ai_comment}
                </div>
              )}
              <div>
                <span className="mr-1">
                  <strong className="mr-1">Name</strong>
                  <span>
                    {candidate?.name} <strong>Position</strong> {candidate?.job_position}
                  </span>
                </span>
              </div>
              <div>
                <div>
                  <span className="mr-1">
                    <strong className="mr-1">Graduation</strong>
                    <span>{candidate?.graduation_details || '*'}</span>
                  </span>
                </div>
                <span className="mr-1">
                  <strong className="mr-1">Nationality</strong>
                  <span>{candidate?.nationality || '*'}</span>
                </span>
                <span className="mr-1">
                  <strong className="mr-1">Gender</strong>
                  <span>{candidate?.gender || '*'}</span>
                </span>
                <span className="mr-1">
                  <strong className="mr-1">Age</strong>
                  <span>{candidate?.age || '*'}</span>
                </span>
                <span className="mr-1">
                  <strong className="mr-1">Experience</strong>
                  <span>{candidate?.experience || '*'} Yrs.</span>
                </span>
                <span className="mr-1">
                  <strong className="mr-1">Languages</strong>
                  <span>{candidate?.languages || '*'}</span>
                </span>
              </div>
              <div>
                <div>
                  <strong>Summarization</strong>
                </div>
                <div className="text-xs">{candidate?.summarization}</div>
              </div>
              <div>
                <div>
                  <strong>Skill</strong>
                </div>
                <div className="text-xs text-blue-500">{candidate?.nomarlized_skills}</div>
                <div className="text-xs">{candidate?.skills}</div>
              </div>
            </div>
          </div>
        </td>
        <td className="px-6 py-4">
          <div className="w-[40px]">
            <a href={baseURL + '/uploads/' + candidate?.filename} target="_blank" rel="noopener noreferrer">
              <img src={FileIcon} alt="edit" />
            </a>
          </div>
        </td>
      </tr>
    );
  };

  const HeaderList = ({ title, backgroundColor = 'yellow', color = 'black' }) => {
    return (
      <div className="px-4 py-2 text-sm font-bold" style={{ backgroundColor: backgroundColor }}>
        <strong style={{ color: color }}>{title}</strong>
      </div>
    );
  };

  const CandidateList = ({ data, type = 'default', HeaderList = () => {} }) => {
    return (
      <div className="overflow-hidden rounded-lg border border-gray-200 shadow-md mb-10">
        <HeaderList />
        <div className="overflow-x-auto">
          <table className="w-full border-collapse bg-white text-left text-sm text-gray-500" style={{ backgroundColor: type === 'highlight' ? '#f5f6ef' : '' }}>
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-4 font-medium text-gray-900">
                  Name
                </th>
                <th scope="col" className="px-6 py-4 font-medium text-gray-900">
                  Infomation
                </th>
                <th scope="col" className="px-6 py-4 font-medium text-gray-900"></th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100 border-t border-gray-100">
              {data.map((candidate, index) => (
                <React.Fragment key={'candidates' + candidate.id + '-' + index}>
                  <CandidateListRender key={index} candidate={candidate} />
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div>
      <input type="file" className="hidden" ref={fileuploader} onChange={handleFileChange} accept=".pdf" />
      <div>
        <h1 style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>
            <img src={AIIcon} alt="AI" style={{ width: '50px', height: '50px', display: 'inline', verticalAlign: 'bottom' }} />
            Resume AI!
          </span>
          <span className="text-xs">
            <a
              style={{ cursor: 'pointer' }}
              onClick={() => {
                dispatch(logoutUser());
              }}
            >
              Logout
            </a>
          </span>
        </h1>
        <div className="relative mt-5">
          <div>
            <textarea defaultValue="โปรแกรมเมอร์ เขียน java ได้อายุ 25 ปีขึ้นไป ประสบการณ์ทำงาน 10ปี" ref={pprompt} className="py-2 px-4 rounded-xl w-full"></textarea>
          </div>
          <div className="mt-2">
            <div className="flex flex-rows gap-1">
              <button onClick={Seach} className="bg-blue-500 hover:bg-blue-700 text-white font-bold overflow-hidden py-2 px-4 rounded-xl w-3/5">
                Seach
              </button>
              <button onClick={clear} className="bg-gray-500 hover:bg-gray-700 text-white font-bold overflow-hidden py-2 px-4 rounded-xl w-1/5">
                Clear
              </button>
              <button onClick={upload} className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold overflow-hidden py-2 px-4 rounded-xl w-1/5">
                Upload
              </button>
            </div>
          </div>
        </div>
        <div className="mt-5">
          {queryResult && <CandidateList data={queryResult.candidates_challenge_data_dict.candidates} type="highlight" HeaderList={() => <HeaderList title="AI Classifier" backgroundColor="#fefebd" />} />}
          <CandidateList data={candidateData} HeaderList={() => <HeaderList title="Candidate List" backgroundColor="#eeeeee" />} />
          {loader && (
            <div className="flex w-screen h-screen items-center justify-center fixed top-0 left-0" style={{ backgroundColor: 'rgb(150 150 150 / 17%)' }}>
              <GridLoader />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { Home };
