import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { errorToast } from '../../utils';
import { loginUser } from '../../store/auth/authActions';

export const Login = () => {
  const { user, loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let username = useRef('');
  let password = useRef('');

  useEffect(() => {
    if (user) {
      navigate('/', { successLogin: true });
    }
  }, [navigate, user]);

  const signinUser = async (e) => {
    e.preventDefault();

    dispatch(
      loginUser({
        username: username.value,
        password: password.value,
      })
    )
      .unwrap()
      .catch((errorData) => {
        errorToast(errorData.error || 'Someting went wrong');
      });
  };

  return (
    <section className="p-8" style={{ background: '#f5f5f5', borderRadius: '15px' }}>
      <Toaster />
      <form onSubmit={signinUser}>
        <div className="mb-8">
          <h1>Login</h1>
        </div>
        <div>
          <label className="block text-primary-grey text-[13px] font-medium pb-1">Username</label>
          <div className="relative">
            <input
              className="inputField w-full mb-8"
              name="username"
              placeholder="Enter your username"
              id="username"
              ref={(e) => {
                username = e;
              }}
              type="text"
              required
            />
          </div>
        </div>
        <div>
          <label className="block text-primary-grey text-[13px] font-medium pb-1">Password</label>
          <div className="relative">
            <input
              className="inputField w-full mb-8"
              name="password"
              placeholder="Enter your password"
              id="password"
              ref={(e) => {
                password = e;
              }}
              type="password"
              required
            />
          </div>
        </div>
        <button type="submit" className="primaryButton mt-4">
          {loading ? 'Loading...' : 'Login'}
        </button>
      </form>
    </section>
  );
};
